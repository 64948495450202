import { createApp } from 'vue'
import App from './App.vue'
import gsap from "gsap";
import ArcoVue from '@arco-design/web-vue';
import '@arco-design/web-vue/dist/arco.css';

import router from './router'

const app = createApp(App)
app.config.globalProperties.$gsap = gsap;
app.use(router).mount('#app');
app.use(ArcoVue)
// App.use(ArcoVue);

