<template>
  <router-view/>
</template>

<style lang="scss">
body{
  padding:0px !important;
  margin:0px !important;
  overflow: hidden;
}
</style>
