import {defineComponent, onMounted, reactive, ref, toRaw, watch, watchEffect} from "vue";
import animate from "@/components/animate";
import resultSuccessOrError from "@/components/result_success_or_error/index";
import {changeResult, changeTime, listUserIn, listUserInputIn, listUserInputOut, titleIn, titleOut} from "@/components/animate/autoSize/componentSize";
import "./main.sass";
import axios from "axios";
import {useRoute} from "vue-router";
declare let topSize:object;
export default defineComponent({
    components:{
        resultSuccessOrError
    },
    setup(){

        // const globalUrl = "http://localhost:9003";
        const globalUrl = "https://homeworkapi.wuhupoo.cn";

        document.onkeydown = function (e) { // 回车提交表单
            const theEvent:any = window.event || e;
            const code = theEvent.keyCode || theEvent.which || theEvent.charCode;
            if (code == 13&&domController.input == true) {
                addUser();
            }
        }

        const domController = reactive({
            title:false,
            user:false,
            input:false,
            loading:false,
            result: false
        })

        const topSize = ref<HTMLElement>();

        const titleDom = ref<HTMLElement>();

        const listDom = ref<HTMLElement>();

        const inputDom = ref<HTMLElement>();

        const resultDom = ref();

        const route = useRoute();
        const postDate = ref({id:"",title:"",endTime:""})

        //初始化帖子
        const startPost = ()=>{
            axios.get(`${globalUrl}/getlist?id=${route.params.id}`).then((res)=>{
                postDate.value.id = res.data.data.id;
                postDate.value.title = res.data.data.title;
                postDate.value.endTime = res.data.data.endTime;
                startTime();
            })
        }

        //初始化
        onMounted(async ()=>{
            checkUser();
            startPost();


        })

        //初始化确认用户
        const checkUser = ()=>{
            let newUser:string;

            try{
                userList.data = JSON.parse(String(window.localStorage.getItem("newUser")));
            }catch (error){
                window.localStorage.removeItem("newUser");
            }
            //第一步，确认合法性
            try{
                for(let item of userList.data){
                    axios.get(`${globalUrl}/search?learnNumber=${item.learnNumber}`).then((res)=> {
                        if (res.data.status === "201") {
                            alert("本地数据库不合法");
                            window.localStorage.removeItem("newUser");
                            userList.data = [];
                        }
                    });
                    item= "";
                }
            }catch (error){
                window.localStorage.removeItem("newUser");
                userList.data = [];
            }

            if(userList.data.length > 4)
            {
                alert("发癫是吧？")
                window.localStorage.removeItem("newUser");
                userList.data = [];
            }
            openUser();


        }
        //openUser(动画)
        const openUser =  ()=>{

            if(loadingStatus) return //加载中禁止打开用户框

            //打开user框
            setTimeout(()=>{
                listUserIn(topSize.value!,listDom.value!,domController);
            },0)

        }


        //切换输入框
        const openInput =()=>{
            nowUser.value.name = "";
            nowUser.value.learnNumber ="";
            nowUser.value.qqNumber = "";

            setTimeout(()=>{
                if (topSize.value)
                    listUserInputIn(topSize.value);
            },0)
            setTimeout(()=>{
                if (listDom.value){
                    domController.user = false;
                    domController.input = true;
                }

            },200)
        }


        const inputValue = ref();
        const userList:any = reactive({data:[]}); //选择用户列表
        //添加用户
        const addUser = ()=>{
            axios.get(`${globalUrl}/search?learnNumber=${inputValue.value}`).then((res)=>{

                if(res.data.status === "201"){
                    alert("查无此人");
                    inputValue.value = "";
                    return;
                }

                //查询是否发生重复行为
               if(checkMoreUser(res.data.data.name)){
                   if(userList.data.length >= 4)
                       userList.data.splice(0,1);
                   userList.data.push(res.data.data);
               }

                if (topSize.value)
                listUserInputOut(topSize.value);
                setTimeout(()=>{
                    domController.input = false;
                    domController.user = true;
                },300)

                window.localStorage.setItem("newUser",JSON.stringify(userList.data))

                inputValue.value = "";
            });
        }

        //选择用户
        const nowUser = ref({name:"",learnNumber:"",qqNumber:""})
        const changeUser = (userItem:object)=>{
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            nowUser.value.name = userItem.name;
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            nowUser.value.learnNumber =userItem.learnNumber
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            nowUser.value.qqNumber = userItem.qqNumber;
            // if(topSize.value)
            // listUserOut(topSize.value)

            //改变为时间
            changeTime(topSize.value!,titleDom.value!,domController)




            // setTimeout(()=>{
            //     domController.user = false
            //     domController.title = true;
            //     console.log("执行关闭");
            // },300)
        }

        //用于查询是否重复的
        const checkMoreUser=(name:string)=>{
            for(let item of userList.data){
                if(item.name === name){
                    return false;
                }
                item = ""
            }
            return true;
        }

        const loadingDom = ref<HTMLElement>()
        let loadingStatus = false;
        //开始加载
        const loadingIn=()=>{
            loadingStatus = true;
            resultDom.value!.startPost();
            if(titleDom.value)
                titleOut(titleDom.value);
            setTimeout(()=>{
                domController.title = false;

                domController.loading = true
                if(loadingDom.value)
                    titleIn(loadingDom.value)
            },500)
        }
        //结束加载
        const loadingOut=()=>{
            loadingStatus = false
            resultDom.value!.endPost();
            setTimeout(()=>{
                if(loadingDom.value)
                    titleOut(loadingDom.value);
                    loadingDom.value?.classList.add("title-user-list-loading")
                setTimeout(()=>{
                    changeResult(topSize.value!,resultDom.value.resultDom!,domController)

                    // domController.title = true;
                    //
                    // domController.loading = false
                    // if(titleDom.value)
                    //     titleIn(titleDom.value)



                },500)
            },1000)
        }

        const updateList:any = reactive({data:[],listData:[]});
        //事实监听是否加载完毕
        watch(updateList.listData,()=>{
                if(updateList.listData.length === 0) loadingOut();
        });



        const uploadRef = ref()
        const startUpdate = ()=>{
            if(loadingStatus) return;
            if(updateList.listData.length === 0) {
                alert("未提交任何文件");
                return 0;
            }
            loadingIn();
            uploadRef.value.submit();
            // console.log("开始上传")
            // console.log(updateList.data)
        }
        //传图片后
        const miaomiaoBefore = (file:any)=>{
            const fileType = {
                name: file.name,
                type: file.type
            }
            updateList.listData.push(fileType)
            // console.log(file);
            // console.log(updateList.listData);
            //must return true
            return true;
        }
        //上传成功后的作业
        const successHomework:any = ref([]);
        //上传失败的作业
        const failHomework:any = ref([]);
        //上传失败
        const updateError = (res:any)=>{
            // alert("上传失败")
            failHomework.value.push(res);
            controllerListView(res);
        }
        //上传成功
        const updateSuccess = (res:any)=>{
            successHomework.value.push(res);
            controllerListView(res);
        }

        const controllerListView=(res:any)=>{
            for (let item in updateList.listData){
                if(toRaw(res).name==updateList.listData[item].name){
                    updateList.listData.splice(item,1)
                }
                item = ""
            }
        }
        const showTimes = reactive({
            day: "0",
            hr: "0",
            min: "0",
            sec: "0",
            ms: "0"
        })
        // onMounted(()=>{
        //     startTime();
        // })
        const closeHomeWork = ()=>{
            successHomework.value  = [];
            failHomework.value = [];
            changeTime(topSize.value!,titleDom.value!,domController)

        }
        function startTime() {
            let day = 0;
            let hr: number | string = 0;
            let min: number | string = 0;
            let sec: number | string = 0;
            let ms = 0;

            const newExpire = 0.3;     //过期时间 3分钟
            const created_at = 'xxxx-xx-xx hh:ss:mm'     //倒计时开始时间


            const now = new Date();
            //月份是从0开始算的  //这里是设置截止时间的
            const outTime = new Date(2022,9,7,23,0 );


            const tim = setInterval(function () {//循环函数countdown
                countdown();
            }, 10)

            function countdown() {
                const minutes = Number(newExpire);
                const time_now = new Date().getTime();
                //直接设置截止日期
                //new Date(yyyy,mth,dd,hh,mm,ss);
                // const msec = outTime.getTime() - time_now;
                const msec = Number(postDate.value.endTime) - time_now;

                //旧的
                // const msec = (Number(now.getTime())+(minutes*60000)) - time_now;
                if (msec >0) {
                    day = parseInt(String(msec / 1000 / 60 / 60 / 24));   //天
                    hr = parseInt(String(msec / 1000 / 60 / 60 % 24));   //时
                    min = parseInt(String(msec / 1000 / 60 % 60));    //分
                    sec = parseInt(String(msec / 1000 % 60));      //秒
                    ms = Math.floor(msec % 1000);   //毫秒
                    let msS: any;
                    // eslint-disable-next-line prefer-const
                    msS = String(ms).substring(0, 2);
                    // eslint-disable-next-line no-self-assign


                    // day = day;
                    hr = hr > 9 ? hr : '0' + hr;
                    min = min > 9 ? min : '0' + min;
                    sec = sec > 9 ? sec : '0' + sec;

                    if (msec < 2000 && String(day) !== "0")
                        showTimes.day = "0";
                    else
                        showTimes.day = String(day);

                    showTimes.hr = String(hr);
                    showTimes.min = String(min);
                    showTimes.sec = String(sec);
                    showTimes.ms = msS;

                } else {
                    clearInterval(tim);
                    day = 0;
                    hr = 0;
                    min = 0;
                    sec = 0;
                    ms = 0;
                    showTimes.day = String(day);
                    showTimes.hr = String("00");
                    showTimes.min = String("00");
                    showTimes.sec = String("00");
                    showTimes.ms = String("00");
                }
            }
        }
        const loadingTotal = ref();
        // watch(loadingTotal,()=>{
        //     console.log(loadingTotal.value);
        // })
        const upLoadingTotal = (status:number)=>{
            console.log(JSON.parse(JSON.stringify(status)).percent)
            loadingTotal.value = parseInt(String(JSON.parse(JSON.stringify(status)).percent * 100));
        }
        return ()=>(
            <div class="home-main-box">
                {/*头部*/}

                <div class="home-main-box-title" ref={topSize} >
                    {/*//正在加载形态*/}
                    {domController.loading ? <div class="title-user-list-loading time-title" ref={loadingDom}>
                        <img src = {require("./images/loading.svg")} alt = "loading" /><span>正在上传文件 {loadingTotal.value}% ···</span></div>:""}
                    {/*//倒计时形态*/}
                     <div style={{display: !domController.title ? "none":"block",position: "relative",letterSpacing:"1px"}}  ref={titleDom}>距离截止提交时间：{showTimes.day}天{showTimes.hr}小时{showTimes.min}分{showTimes.sec}秒{showTimes.ms}毫秒
                    </div>
                    {/*//选择用户形态*/}
                    { <div style={{display: !domController.user ? "none !important":"grid !important"}} ref = {listDom} id="userList" class = "title-user-list">
                        <div class = "title-user-list-title">选择使用的账户</div>
                        {userList.data.map((item:any,index:any)=>(
                            <div class = "title-user-list-child" onClick={()=>changeUser(item)}>
                                <div class = "home-information-box">
                                    <div class = "home-information-bottom-avatar">
                                        <img src = {`https://q1.qlogo.cn/g?b=qq&nk=${item.qqNumber}&s=3`} alt = "avatar" />
                                    </div>
                                    <div class = "home-information-bottom-child">
                                        <div>{item.name}</div>
                                        <div>{item.learnNumber}</div>
                                    </div>
                                </div>
                            </div>
                        ))}
                        <div class = "title-user-list-add" onClick={()=>openInput()}>
                            <div><img src = {require("./images/Union.svg")} alt = "plus" /></div>
                            <div>添加一个新的用户</div>
                        </div>
                    </div>}
                    {/*//输入框形态*/}
                    {<div
                        style={{display: !domController.input ? "none":"block"}}
                        class = "title-user-list-input" ref={inputDom}>
                        <div class="title-user-list-input-child-title">
                            <div>添加一个新的用户</div>
                            <div>使用学号完成信息录入</div>
                        </div>
                        <div class="title-user-list-input-child-input">
                            <input v-model={inputValue.value} type = "text" />
                        </div>
                        <div class="title-user-list-input-child-button">
                            <div class="input-child-tips">
                               <img src = {require("./images/zy.svg")} alt = "!" />
                                <span>系统将会保存资料，以便于防止下一次重复录入</span>
                            </div>
                            <div class="input-child-button">
                                <div onClick={()=>addUser()}>录入信息</div>
                            </div>
                        </div>
                    </div>}
                    {/*//小票形态*/}
                    <resultSuccessOrError
                        style={{display: !domController.result ? "none":"block"}}
                        personalInformation={nowUser.value}
                        successHomework={successHomework.value}
                        failHomework={failHomework.value}
                        onReturnBackIKnow={()=> closeHomeWork()}
                        ref = {resultDom}></resultSuccessOrError>

                </div>
                <div class="home-main-information">
                    <div class="home-main-information-use-box">
                        <div class="home-information-title">{postDate.value.title}
                        </div>
                        <a-upload style={{width:"100%"}}
                                  auto-upload={false}
                                  ref={uploadRef}
                                  onError={updateError}
                                  multiple={true}
                                  onSuccess={updateSuccess}
                                  onProgress={(total:number)=>upLoadingTotal(total)}
                            // file-list={updateList.listData}
                                  on-before-upload={miaomiaoBefore}
                                  disabled={nowUser.value.learnNumber == "" || domController.loading == true ? true:false}
                                  data={{'learnNumber':nowUser.value.learnNumber,'postId':postDate.value.id}}  action={`${globalUrl}/updata`} v-slots={{"upload-button":()=>
                                <div class = "home-information-upData">
                                    {updateList.listData.length !== 0 ?
                                        <div class="home-information-list-file">{updateList.listData.map((item:any,index:any)=>(
                                        <div class="home-information-upData-child time-title">{item.name}</div>
                                    ))}</div>:
                                   <div class="home-information-list-border">
                                       <span><img src = {require('./images/Vector.svg')} alt = "icon" />拖拽文件此处提交文件或文件夹</span>
                                       {/*<p style="font-size:13px">上传过程失败会有提示</p>*/}
                                   </div>}
                            </div>
                        }}>
                        </a-upload>
                        {/*<div class = "home-information-upData">*/}
                        {/*    <div><span><img src = {require('./images/Vector.svg')} alt = "icon" />拖拽文件此处提交文件或文件夹</span></div>*/}
                        {/*</div>*/}
                        <div class="home-information-bottom">
                            {nowUser.value.name !== "" ? <div class = "home-information-box time-title">
                                <div class = "home-information-bottom-avatar">
                                    <img src = {`https://q1.qlogo.cn/g?b=qq&nk=${nowUser.value.qqNumber}&s=3`} alt = "avatar" />
                                </div>
                                <div class = "home-information-bottom-child">
                                    <div>{nowUser.value.name}</div>
                                    <div>{nowUser.value.learnNumber}</div>
                                </div>
                                <div class = "home-information-bottom-quite">
                                    <img src = {require('./images/quite.svg')} alt = "quite" onClick={()=>openUser()} />
                                </div>
                            </div>:""}
                            <div class="send-button" onClick={()=>startUpdate()}>开始上传</div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
})