import animate from "@/components/animate";
import {gsap} from "gsap"
import {Ref, ref, toRaw, UnwrapNestedRefs} from "vue";
import {TweenMax} from 'gsap'

//变为时间的方法必须传入时间的dom
interface changeTimeInterface{
    title:boolean,
    [propName: string]: any;
}
interface changeUserInterface{
    user:boolean,
    [propName: string]: any;
}
interface changeResultInterface{
    result:boolean,
    [propName: string]:any
}

//新版本的形态变为，变为谁？

//Function: 变成用户列表
//value:    backgroundDom(变化盒子),userListDom(用户列表),viewDom(视图控制对象)
export const listUserIn = (backgroundDom:HTMLElement,userListDom:HTMLElement,viewDom:UnwrapNestedRefs<changeTimeInterface>):void=>{
    backgroundDom.className = "" //历史遗留
    backgroundDom.classList.add("home-main-box-title")
    if(!whetherContinueAnimate("user",viewDom)) return;
    new TweenMax(backgroundDom,1,{
        width: "20%",
        height: "70vh"
    })
    setTimeout(()=>{
        changeDom(viewDom,"user")
        // @ts-ignore
        new TweenMax.fromTo(userListDom,0.5,{top:80,opacity:0},{top:0,opacity:1})},500);
}

//Function: 变为时间
//value:    backgroundDom(变化盒子),timeDom(时间组件),viewDom(视图控制对象)
export const changeTime=(backgroundDom:HTMLElement,timeDom:HTMLElement,viewDom:UnwrapNestedRefs<changeUserInterface>):void=>{
    backgroundDom.className = "" //历史遗留
    backgroundDom.classList.add("home-main-box-title")
    if(!whetherContinueAnimate("title",viewDom)) return;
    //背景盒子dom
    new TweenMax(backgroundDom,1,{
        width:"30%",
        height:"50px"
    });
    //时间dom
    setTimeout(()=>{
        changeDom(viewDom,"title");
        //很多基于 ES5 的构造函数的写法在 TS 的检查下不支持，目前还会有很多较老的库没有更新为 TS 项目，并且已经非常稳定没有维护更新的计划
        // @ts-ignore
        new TweenMax.fromTo(timeDom,0.8,{top: 80,opacity: 0},{
            top:0,
            opacity: 1
        })
    },166)
}

//Function: 变为小票
//value: backgroundDom(变化盒子),resultDom(小票组件),viewDom(视图控制对象)
export const changeResult=(backgroundDom:HTMLElement,resultDom:any,viewDom:UnwrapNestedRefs<changeResultInterface>):void=>{
    backgroundDom.className = "" //历史遗留
    backgroundDom.classList.add("home-main-box-title")
    if(!whetherContinueAnimate("result",viewDom)) return;
    changeDom(viewDom,"result");

    // @ts-ignore
    new TweenMax.fromTo(resultDom,0.3,{opacity:0},{opacity:1})
    new TweenMax(backgroundDom,1,{width:"25%",height:"80vh"})
}

//Function:【私有】修改dom
//value:    viewDom（视图盒子）,whoNeedChange(谁要被打开)
const changeDom = (viewDom:UnwrapNestedRefs<any>,whoNeedChange:string):void=>{
    for(const [key,value] of Object.entries(viewDom)){
        viewDom[key] = whoNeedChange===key? true:false;
    }
}

//Function: 【私有】是否继续动画
//           因为某些时候已经成为了某种状态，如果继续变为这种形态会被重新执行动画，这里将会禁止动画重复执行
//value:     comingSoonAnimate(即将要进行的动画) viewDom(视图控制对象)
const whetherContinueAnimate = (comingSoonAnimate:string,viewDom:UnwrapNestedRefs<any>):boolean=> {
        if(viewDom[comingSoonAnimate]==true) return false;
        return true
}



export const listUserInputIn = (res:HTMLElement)=>{

    res.className = ""
    res.classList.add("home-main-box-title")

    res.classList.add("user-list-box-input-in");
}


export const listUserInputOut = (res:HTMLElement)=>{
    res.className = ""
    res.classList.add("home-main-box-title")


    res.classList.add("user-list-box-input-out");
}

export const titleOut = (res:HTMLElement)=>{
    // res.className = ""
    // res.classList.add("home-main-box-title")

    // res.classList.add("time-title-out");

}

export const titleIn = (res:HTMLElement)=>{
    res.className = ""
    // res.classList.add("home-main-box-title")

    res.classList.add("time-title");

}