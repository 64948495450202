import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import home from "@/views/home";

const routes: Array<RouteRecordRaw> = [
  {
    path: '/:id',
    name: 'home',
    meta:{
      title:"前端班提交作业用"
    },
    component: home
  },
  // {
  //   path: '/about',
  //   name: 'about',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  // }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = String(to.meta.title)
  }
  next()
})
export default router
