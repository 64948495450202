import {computed, defineComponent, h, ref, toRefs, toRaw, toRef} from "vue";
import "./main.sass";
export default defineComponent({
    props:{
      successHomework: Array,
      failHomework: Array,
      personalInformation: Object
    },
    emits:["returnBackIKnow"],
    setup(props,{expose,emit}){

        let startTime:Date,endTime:Date;
        const resultDom = ref<HTMLElement>()
        const startRefTime = ref<Date>()

        const time = ref<number|string>()//上传时间
        const { successHomework,failHomework,personalInformation} = toRefs(props);
        const startPost = ()=>{
            startTime = new Date();
            startRefTime.value = new Date();
        }
        const endPost = () =>{
            endTime = new Date();
            // console.log(JSON.parse(JSON.stringify(successHomework.value)))
            // console.log(personalInformation.value)
            // -1秒是因为，结束动画会延迟一秒结束计算时间；

            time.value =  (((endTime.getTime()-startTime.getTime())/1000));
            if(time.value<0) time.value = 0;
            else time.value=time.value.toFixed(2)

        }

        const iknow = ()=>{
            emit('returnBackIKnow')
        }

        //对父组件暴露方法
        expose({startPost,endPost,resultDom})
        return ()=>h(
            <div ref={resultDom}  class = "title-user-result">
                <div>
                    <div class = "title-top-banner">
                        <div class = "title-result-text">完成作业提交</div>
                        <div class = "title-result-time">{startRefTime.value?.toLocaleString()}</div>
                    </div>
                    <div class = "title-result-success-and-error-box">
                        <div class = "title-result-text-child">完成的提交</div>
                        <div class = "title-result-list-box">
                            {successHomework.value?.map((item:any,index:any)=>(<div>{item.name}</div>))}
                            {successHomework.value?.length == 0 ? <div style={{textAlign:"center"}}>无</div>:""}
                        </div>
                    </div>
                    <div class = "title-result-success-and-error-box">
                        <div class = "title-result-text-child">未完成的提交</div>
                        <div class = "title-result-list-box">
                            {failHomework.value?.map((item: any, index: any) => (<div>{item.name}</div>))}
                            {failHomework.value?.length == 0 ? <div style={{textAlign:"center"}}>无</div>:""}
                        </div>
                            </div>
                    <div class = "title-result-information">
                        <div class = "title-result-information-text">信息</div>
                        <div class = "title-result-column">
                            <div class = "result-information-left">{personalInformation.value?.name}</div>
                            <div class = "result-information-right">{personalInformation.value?.learnNumber}</div>
                        </div>
                        <div class = "title-result-column">
                            <div class = "result-information-left">成功提交文件数</div>
                            <div class = "result-information-right">{successHomework.value?.length}</div>
                        </div>
                        <div class = "title-result-column">
                            <div class = "result-information-left">本次提交上传时间</div>
                            <div class = "result-information-right">{time.value+"s"}</div>
                        </div>
                    </div>
                </div>
                <a-tooltip content-style={{borderRadius:40}} background-color="#998FC0" content="你知道吗，如果想修改作业，只需要提交相同名字的作业即可完成覆盖">
                    <span onClick={()=>iknow()} class = "title-result-button">我知道了</span>
                </a-tooltip>
            </div>
        )
    }
})